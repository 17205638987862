export default [
  {
    title: 'Clients & Scans',
    route: 'home',
    icon: 'SmileIcon',
  },
  
  
  {
    title: 'Commandes',
    route: 'orders',
    icon: 'ShoppingCartIcon',
  },

  {
    title: 'Profil',
    route: 'profile',
    icon: 'UserIcon',
  },
  
  

]
